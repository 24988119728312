$c-primary: #353535;
$c-secandary: #353535;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  margin: auto;
  padding: 1.5rem; //24
  font-size: var(--base-font-size) !important;
  line-height: 1.375;
  letter-spacing: -0.01875rem; //-0.332143px;

  .heading {
    color: white;
    text-align: center;
  }

  .description {
    color: white;
    text-align: left;
    margin-bottom: 1rem; //16
  }

  .footer {
    padding: 1.5rem; //24

    .first_button {
      display: inline-block;
      justify-content: center;
      width: 100%;
      height: 2.8125rem; //45
      border: 1px solid white;
      background: white;
      font-family: var(--base-font-family);
      color: black;
      font-size: 1rem; //16
      border-radius: 0.625rem; //10
      padding: 0.125rem 1rem 0.25rem; //2 16 4
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      user-select: none;
      font-weight: 500;
    }

    .second_button {
      display: inline-block;
      justify-content: center;
      width: 100%;
      height: 2.8125rem; //45
      border: 1px solid white;
      background: white;
      font-family: var(--base-font-family);
      color: black;
      font-size: 1rem; //16
      border-radius: 0.625rem; //10
      padding: 0.125rem 1rem 0.25rem; //2 16 4
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      user-select: none;
      font-weight: 500;
      margin-top: 1rem; //16
    }
  }
}
