$c-primary: #353535;
$c-secandary: #353535;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$primary: var(--primary);
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  text-align: left;

  .description {
    text-align: left;
    margin-bottom: 1.5rem; //24
  }
  .container {
    max-width: calc(100vw - 3rem);
    .userDetailRow {
      position: relative;
      & > label {
        &:first-child {
          position: absolute;
          left: 0;
          top: 0;
          width: 35%;
          font-weight: bold;
          font-size: 1rem; //16
          line-height: 1.2;
          z-index: 1;
          height: 3.75rem; //60
          display: flex;
          align-items: center;
        }
      }
      .address-label-span {
        height: 5rem !important; //80
      }
    }
    .check-confirm {
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }

    .title {
      font-weight: 600;
      padding-bottom: 20px;
    }

    .subheading {
      font-family: var(--heading-font-family) !important;
      color: var(--heading-text-color) !important;
      font-weight: 600;
      font-size: 1.2rem;
    }

    .selectDescription {
      margin-top: 1rem; //16
    }

    .select {
      height: auto;
      margin-top: 1rem; //16
      padding-bottom: 20px;
    }
  }

  .error {
    color: red;
    font-size: 0.75rem; //12
  }

  .input {
    .halfWith {
      max-width: 50%;
      margin-right: 1rem !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
