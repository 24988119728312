$c-primary: #353535;
$c-secandary: #353535;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.formInputRow {
  position: relative;

  & > span {
    &:first-child {
      position: absolute;
      left: 0;
      top: 0;
      width: 35%;
      font-weight: bold;
      font-size: 1rem; //16
      line-height: 1.2;
      z-index: 1;
      height: 3.75rem; //60
      display: flex;
      align-items: center;
    }
  }
}

.control {
  background-color: white;
  border-bottom: 1px solid rgba(25, 25, 25, 0.32);
  border-radius: 0;
  padding-left: 35%;
}
