$c-primary: #353535;
$c-secandary: #353535;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .heading {
    margin-bottom: 3rem; //48
  }

  .description {
    text-align: left;
  }

  .space {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
  }

  .hyperlink {
    margin-bottom: 1.5rem; //24
  }
}
